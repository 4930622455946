import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import useICartridge from "../context/hooks/useICartridge";
import { EditUserProfileForm } from "../monsterComponents/forms/editUserProfileForm";
import { editUser } from "../services/userService";
import { ErrorHelper } from "../utils/ErrorHelper";

export const EditUserProfile = (props) => {
    const { user, setUser } = useICartridge();

    const [state, setState] = useState({
        name: "",
        password: "",
        errors: { code: 0, errors: [] },
    })

    const history = useHistory();

    useEffect(() => {
        (async () => {
            try {
                await props.handleRefreshToken()
                setState({ ...state, name: user.name });
            } catch (error) {
                setState({ ...state, errors: ErrorHelper(error) });
            }
        })();
    }, [])

    const editUserSubmit = async (e: any) => {
        e.stopPropagation();
        e.preventDefault();

        let userUpdate = {
            id: user.id,
            name: state.name,
            email: user.email,
            password: state.password,
            roles: user.roles.map(r => { return (r.id) })
        }

        try {
            let response = await editUser(user.id, userUpdate);

            // let loggedInUser = user;
            // loggedInUser.name = state.name;

            let loggedInUser = { ...user, name: state.name };

            setUser(loggedInUser);
            localStorage.setItem('loggedInUser', JSON.stringify(loggedInUser));
            history.push("/");
            toast.success(`User profile '${state.name}' updated.`);
        } catch (error) {
            setState({ ...state, errors: ErrorHelper(error) });
        }
    }

    return (
        <div className="page-content container-fluid">
            <EditUserProfileForm
                user={user}
                state={state}
                setState={setState}
                editUser={editUserSubmit}
            />
        </div>
    );
}

import React from "react";
import { Link } from "react-router-dom";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Table } from "reactstrap";
import { SelectDropDown } from "../components/dropDown";

export const UsersList = (
  {
    usersList,
    clients,
    selectedClient,
    setSelectedClient,
    isAdminRole,
    isLoading
  }
) => {
  return (
    <div className="card">
      <div className="card-body">
        {
          isAdminRole &&
          <div className="row">
            <div className="col-sm-3">
              <SelectDropDown
                selectedValue={selectedClient}
                setSelectedValue={setSelectedClient}
                values={clients.map(c => {
                  return (
                    {
                      value: c.id,
                      label: c.name
                    }
                  );
                })}
                placeholder={"Select client"}
              />
            </div>

            <div className="col-sm-9 d-flex justify-content-end">
              <Link to='/users/create'>
                <button type="button" className="btn btn-info btn-rounded m-t-10 mb-2">Add New User</button>
              </Link>
            </div>
          </div>
        }
        {
          (usersList == null || usersList.length < 1) && !isLoading ?
            <div>No results.</div>
            :
            (
              <Table responsive size="sm" className="table customize-table mb-0 v-middle">
                <thead className="table-light">
                  <tr>
                    <th className="border-bottom border-top">Name</th>
                    <th className="border-bottom border-top">Email</th>
                    <th className="border-bottom border-top"></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    usersList.map((u, index) => {
                      return (
                        <tr key={index}>
                          <td>{u.name}</td>
                          <td>{u.email}</td>
                          <td>
                            <UncontrolledDropdown setActiveFromChild>
                              <DropdownToggle tag="a" className="nav-link">
                                <img src={"/images/more-horizontal.svg"} data-feather="more-horizontal" className="feather-sm"></img>
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem tag="a" href={`/users/edit/${u.id}`}>
                                  Edit
                                </DropdownItem>
                                <DropdownItem tag="a" href="#">
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </Table>
            )
        }
      </div>
    </div>
  );
}
import { useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import IUser from "../../models/IUser";
import { logoutUser, refreshToken } from "../../services/loginService";
import { ErrorHelper } from "../../utils/ErrorHelper";

export default function useToken() {
    const history = useHistory();

    const getToken = (): any => {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(`{ "token": ${tokenString as string} }`);
        return userToken?.token;
    };

    const getTokenExpirationDate = (): any => {
        const tokenExpirationString = localStorage.getItem('expirationDate');
        const userTokenExpirationDate = JSON.parse(`{ "tokenExpiration": ${tokenExpirationString as string} }`);
        return userTokenExpirationDate?.tokenExpiration;
    };

    const saveToken = (userToken: any, tokenExpiration: number, loggedInUser?: IUser) => {
        const date = new Date();
        // date in miliseconds, so need to convert to minutes // adding minutes, so 1minute 60seconds * 1000miliseconds
        const expirationTokenDate = date.getTime() + tokenExpiration * 60000;

        localStorage.setItem('token', JSON.stringify(userToken));
        localStorage.setItem('expirationDate', JSON.stringify(expirationTokenDate));

        setToken(userToken);
        setTokenExpiration(expirationTokenDate);

        if (loggedInUser != null) {
            localStorage.setItem('loggedInUser', JSON.stringify(loggedInUser));
        }
    };

    const refreshTokenAction = async () => {
        try {
            let response = await refreshToken();
            saveToken(response.token, response.expires_in);
        } catch (error) {
            toast.error(ErrorHelper(error).errors[0]);
        }
    }

    const removeToken = async () => {
        try {
            let response = await logoutUser();

        } catch (error) {
            toast.error(ErrorHelper(error).errors[0]);
        }

        localStorage.clear();

        setToken("")
        setTokenExpiration(0);

        history.push("/login");
    };

    const [token, setToken] = useState(getToken());
    const [tokenExpiration, setTokenExpiration] = useState(getTokenExpirationDate());

    return {
        setToken: saveToken,
        token,
        tokenExpiration,
        removeToken,
        refreshTokenAction
    }
}


export const LogoutSection = (props) => {
    return (
        <div className="sidebar-footer">
            <a></a>
            <a href="#" onClick={async () => await props.logout()} className="link" data-bs-toggle="tooltip" data-bs-placement="top" title="Logout"><i className="mdi mdi-power"></i></a>
            <a></a>
        </div>
    );
};
import Select from 'react-select'

export const MultiSelectDropDown = ({
    values,
    selectedValues,
    setValues,
    entity,
    placeholder,
    id,
    className = ""
}) => {
    return (
        <Select
            name="select"
            className={className}
            id={id}
            required={true}
            value={entity != null ? selectedValues : undefined}
            options={values}
            isMulti
            onChange={(event: any) => setValues(event)}
            placeholder={placeholder}
        />
    );
}

export const SelectDropDown = ({
    selectedValue,
    setSelectedValue,
    values,
    placeholder,
    callback = null,
    disabled = false,
    className = ""
}) => {
    return (
        <Select
            isDisabled={disabled}
            value={selectedValue}
            options={values}
            onChange={(event: any) => {
                setSelectedValue(event);
                if (callback != null) {
                    callback(event);
                }
            }
            }
            display="none"
            placeholder={placeholder}
            className={className}
        />
    );
}

import React from "react";
import { Link } from "react-router-dom";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Table } from "reactstrap";

export const ClientsList = (
  {
    clientsList,
  }
) => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-end">
              <Link to='/clients/create'>
                <button type="button" className="btn btn-info btn-rounded m-t-10 mb-2">Add New Client</button>
              </Link>
            </div>
            <Table responsive size="sm"
              className="table customize-table mb-0 v-middle">
              <thead className="table-light">
                <tr>
                  <th className="border-bottom border-top">Name</th>
                  <th className="border-bottom border-top">Sensors</th>
                  <th className="border-bottom border-top"></th>
                </tr>
              </thead>
              <tbody>
                {
                  clientsList.map((c, index) => {
                    return (
                      <tr key={index}>
                        <td>{c.name}</td>
                        <td>
                          {c.sensors.map(s => {
                            return (
                              s.name + (c.sensors[c.sensors.length - 1] == c.sensors[c.sensors.indexOf(s)] ? "" : ", ")
                            );
                          })}
                        </td>
                        <td>
                          <UncontrolledDropdown setActiveFromChild>
                            <DropdownToggle tag="a" className="nav-link">
                              <img src={"/images/more-horizontal.svg"} data-feather="more-horizontal" className="feather-sm"></img>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem tag="a" href={`/clients/edit/${c.id}`}>
                                Edit
                              </DropdownItem>
                              <DropdownItem tag="a" href="#">
                                Delete
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}
import { MultiSelectDropDown } from "../components/dropDown";

export const ClientForm = ({
    name,
    setName,
    sensors,
    clientSensors,
    setClientSensors,
    formTitle,
    client,
    addClient,
    editClient,
    errors
}) => {
    let errorsObjectNames: string[] = [];
    errorsObjectNames = Object.keys(errors?.errors);

    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="card">
                    <div className="border-bottom title-part-padding">
                        <h4 className="card-title mb-0">{formTitle}</h4>
                    </div>
                    <div className="card-body">
                        <form
                            onSubmit={async (e) => {

                                client == null ? await addClient(e) : await editClient(e);
                            }}
                        >
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="name">Name <span className="text-danger">*</span></label>
                                    <input
                                        value={name ?? ""}
                                        type="text"
                                        className={errorsObjectNames.includes("name") ? "form-control is-invalid" : "form-control"}
                                        id="name"
                                        required
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    <div className="invalid-feedback">
                                        {errorsObjectNames.includes("name") && errors?.errors["name"][0]}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className=" col-md-6 mb-3">
                                    <label htmlFor="sensors">Sensors <span className="text-danger">*</span></label>
                                    <MultiSelectDropDown
                                        id={"sensors"}
                                        values={sensors.map(s => {
                                            return (
                                                {
                                                    value: s.id,
                                                    label: s.name
                                                }
                                            );
                                        })}
                                        selectedValues={clientSensors}
                                        setValues={setClientSensors}
                                        entity={client}
                                        placeholder={"Select sensors"}
                                        className={errorsObjectNames.includes("sensors") ? "is-invalid" : ""}
                                    />
                                    <div className="invalid-feedback">
                                        {errorsObjectNames.includes("sensors") && errors?.errors["sensors"][0]}
                                    </div>
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="btn btn-success rounded-pill px-4"
                            >
                                <div className="d-flex align-items-center">
                                    <i data-feather="save" className="feather-sm me-1 fill-icon"></i> Save
                                </div>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
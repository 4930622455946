import { NavLink, useHistory } from "react-router-dom";
import { Nav } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import FeatherIcon from "feather-icons-react";
import { LogoutSection } from "../monsterComponents/components/logoutSection";
import useToken from "../context/hooks/useToken";
import { useEffect } from "react";

const Sidebar = () => {
    const { removeToken } = useToken();
    const history = useHistory();

    const activeRoute = (routeName) => {
        return history.location.pathname.indexOf(routeName) > -1 ? "selected" : "";
    };

    /*--------------------------------------------------------------------------------*/
    /*To Expand SITE_LOGO With Sidebar-Menu on Hover                                  */
    /*--------------------------------------------------------------------------------*/
    const expandLogo = () => {
        document.getElementById("logobg").classList.toggle("expand-logo");
    };
    /*--------------------------------------------------------------------------------*/
    /*Verifies if routeName is the one active (in browser input)                      */
    /*--------------------------------------------------------------------------------*/

    /*--------------------------------------------------------------------------------*/
    /*Its for scroll to to                    */
    /*--------------------------------------------------------------------------------*/

    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const showMobilemenu = () => {
        if (window.innerWidth < 800) {
            let element = document.getElementById("main-wrapper")
            element.classList.toggle("show-sidebar");
        }
    };

    return (
        <aside
            className="left-sidebar"
            id="sidebarbg"
            data-sidebarbg={"skin6"}
            onMouseEnter={expandLogo.bind(null)}
            onMouseLeave={expandLogo.bind(null)}
        >
            <div className="scroll-sidebar">
                <PerfectScrollbar className="sidebar-nav">
                    {/*--------------------------------------------------------------------------------*/}
                    {/* Sidebar Menus will go here                                                */}
                    {/*--------------------------------------------------------------------------------*/}
                    <Nav id="sidebarnav">
                        {MenuRoutes.map((prop, key) => {
                            if (prop.redirect) {
                                return null;
                            } else if (prop.navlabel) {
                                return (
                                    <li className="nav-small-cap" key={key}>
                                        <i className={prop.icon}></i>
                                        <span className="hide-menu">{prop.name}</span>
                                    </li>
                                );
                                /*--------------------------------------------------------------------------------*/
                                /* Child Menus wiil be goes here                                                    */
                                /*--------------------------------------------------------------------------------*/
                            } else if (prop.collapse) {
                                let firstdd = {};
                                //firstdd[prop.state] = !state[prop.state];

                                return (
                                    <li
                                        className={activeRoute(prop.path) + " sidebar-item"}
                                        key={key}
                                    >
                                        <span
                                            data-toggle="collapse"
                                            className="sidebar-link has-arrow"
                                        //aria-expanded={state[prop.state]}
                                        //onClick={() => setState(firstdd[prop.state])}
                                        >
                                            <FeatherIcon icon={prop.icon} />
                                            {/* <i className={prop.icon} /> */}
                                            <span className="hide-menu">{prop.name}</span>
                                        </span>

                                    </li>
                                );
                            } else {
                                return (
                                    /*--------------------------------------------------------------------------------*/
                                    /* Adding Sidebar Item                                                            */
                                    /*--------------------------------------------------------------------------------*/
                                    <li
                                        onClick={scrollTop}
                                        className={
                                            activeRoute(prop.path) +
                                            //(prop.pro ? " active active-pro" : "") 
                                            //"active active-pro" +
                                            " sidebar-item"
                                        }
                                        key={key}
                                    >
                                        <NavLink
                                            to={prop.path}
                                            onClick={showMobilemenu}
                                            className="sidebar-link"
                                            activeClassName="active"
                                        >
                                            <FeatherIcon icon={prop.icon} />
                                            {/* <i className={prop.icon} /> */}
                                            <span className="hide-menu">{prop.name}</span>
                                        </NavLink>
                                    </li>
                                );
                            }
                        })}
                    </Nav>
                </PerfectScrollbar>
            </div>
            <LogoutSection logout={removeToken} />
        </aside>
    );
};

var MenuRoutes = [
    {
        navlabel: true,
        name: "MENU",
        icon: "mdi mdi-dots-horizontal",
    },
    {
        collapse: false,
        path: "/dashboard",
        name: "Dashboard",
        icon: "home",
        child: [],
        redirect: false
    },
    {
        collapse: false,
        path: "/clients",
        name: "Clients",
        icon: "user",
        child: [],
        redirect: false
    },
    {
        collapse: false,
        path: "/users",
        name: "Users",
        icon: "users",
        child: [],
        redirect: false
    },
    {
        collapse: false,
        path: "/devices",
        name: "Devices",
        icon: "tablet",
        child: [],
        redirect: false
    }
]

export default Sidebar;

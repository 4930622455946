import { useContext, useState } from 'react';
import loginUser from '../../services/loginService'
import { Login as LoginForm } from '../../monsterComponents/forms/login'
import useToken from '../../context/hooks/useToken';
import { Redirect, useHistory } from 'react-router';
import { ErrorHelper } from '../../utils/ErrorHelper';
import { getLoggedInUser } from '../../services/userService';
import { toast } from 'react-toastify';
import useICartridge from '../../context/hooks/useICartridge';

export default function Login() {
    const {
        setUser
    } = useICartridge();

    const {
        token,
        tokenExpiration,
        setToken,
    } = useToken();

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const history = useHistory();

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        try {
            const loginResponse = await loginUser({
                email,
                password
            });

            const loggedInUser = await getLoggedInUser(loginResponse.token);
            setUser(loggedInUser);

            setToken(loginResponse.token, loginResponse.expires_in, loggedInUser);
        }
        catch (error) {
            let errorResponse = ErrorHelper(error);
            if (errorResponse.code === 422) {
                toast.error(ErrorHelper(error).errors["email"][0]);
                return;
            }
            toast.error(ErrorHelper(error).errors[0]);
        }
    }

    // if token OK redirect to dashboard
    if (token && tokenExpiration > Date.now()) {
        return <Redirect to='/dashboard' />
    }

    return (
        <div className="auth-wrapper d-flex no-block justify-content-center align-items-center">
            <div className="auth-box p-4 bg-white rounded">
                <LoginForm
                    handleSubmit={handleSubmit}
                    setEmail={setEmail}
                    setPassword={setPassword}
                />
            </div>
        </div>
    )
}



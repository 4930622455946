import React, { useEffect, useState } from "react";
import { ClientsList } from "../../../monsterComponents/lists/clientsList";
import { getClients } from "../../../services/clientService";
import { ErrorHelper } from "../../../utils/ErrorHelper";
import { PaginationComponent } from "../../../monsterComponents/components/paginationComponent";
import { toast } from "react-toastify";
import { Redirect, useHistory } from "react-router";

export const Clients = (props) => {
    const [clients, setClients] = useState([]);
    const [pagination, setPagination] = useState({ currentPage: 1, count: 0, totalPages: 0 });
    const [errorCode, setErrorCode] = useState(0);

    const history = useHistory();

    useEffect(() => {
        (async () => {
            try {
                await props.handleRefreshToken();
                let clients = await getClients(pagination.currentPage);
                setClients(clients.data);
                setPagination({
                    currentPage: clients.pagination.currentPage,
                    count: clients.pagination.count,
                    totalPages: clients.pagination.totalPages,
                });
            } catch (error) {
                toast.error(ErrorHelper(error).errors[0]);

                if (ErrorHelper(error).code == 403) {
                    setErrorCode(403)
                    history.push("/")
                }
            }
        })();
    }, [pagination.currentPage]);

    return (
        <div className="page-content container-fluid">
            <ClientsList
                clientsList={clients}
            />
            <PaginationComponent
                pagination={pagination}
                setPagination={setPagination}
            />
        </div>
    );
}

export const DeviceForm = ({
    state,
    editDevice,
    setState
}) => {
    let errorsObjectNames: string[] = [];
    errorsObjectNames = Object.keys(state.errors?.errors);

    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="card">
                    <div className="border-bottom title-part-padding">
                        <h4 className="card-title mb-0">Edit Device</h4>
                    </div>
                    <div className="card-body">
                        <form
                            onSubmit={async (e) => {
                                await editDevice(e);
                            }}
                        >
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="ident">Ident</label>
                                    <input
                                        type="text" className="form-control" id="ident"
                                        disabled
                                        value={state.ident ?? ""}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="name">Name</label>
                                    <input
                                        type="text"
                                        className={errorsObjectNames.includes("name") ? "form-control is-invalid" : "form-control"}
                                        id="name"
                                        value={state.name ?? ""}
                                        onChange={(e) => setState({ ...state, name: e.target.value })}
                                    />
                                    <div className="invalid-feedback">
                                        {errorsObjectNames.includes("name") && state.errors?.errors["name"][0]}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="created_at">Created at</label>
                                    <input
                                        type="text" className="form-control" id="created_at"
                                        disabled
                                        value={state.createdAt ?? ""} />
                                </div>
                            </div>
                            <button type="submit" className="btn btn-success rounded-pill px-4">
                                <div className="d-flex align-items-center">
                                    <i data-feather="save" className="feather-sm me-1 fill-icon"></i> Save
                                </div>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
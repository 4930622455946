import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { UserForm } from "../../../monsterComponents/forms/userForm";
import { getClientsList } from "../../../services/clientService";
import { addUser, editUser, getRoles, getUser } from "../../../services/userService";
import { ErrorHelper } from "../../../utils/ErrorHelper";

export const CreateUser = (props) => {
    const { id }: any = useParams();

    const [client, setClient] = useState(null);
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [allClients, setAllClients] = useState([]);
    const [roles, setRoles] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [user, setUser] = useState(null);
    const [errors, setErrors] = useState({ code: 0, errors: [] });

    const history = useHistory();

    useEffect(() => {
        (async () => {
            try {
                await props.handleRefreshToken()
                let clients = await getClientsList();
                let roles = await getRoles();
                setAllClients(clients);
                setRoles(roles);

                if (id != null && id > 0) {
                    let user = await getUser(id);
                    setUser(user);

                    setFullName(user.name);
                    setEmail(user.email);
                    // map client for select list object
                    setClient({ value: user.clients[0].id, label: user.clients[0].name });

                    // map object sensors for multi select field 
                    setSelectedRoles(user.roles.map(r => {
                        return (
                            {
                                value: r.id,
                                label: r.name
                            }
                        );
                    }));
                }
            } catch (error) {
                toast.error(ErrorHelper(error).errors[0]);
                if (ErrorHelper(error).code == 403) {
                    history.push("/");
                }
            }
        })();
    }, [])

    let formTitle = id != null ? "Edit User" : "Add User"

    const addNewUser = async (e: any) => {
        e.stopPropagation();
        e.preventDefault();

        let clientId = client?.value;

        let rolesIds = selectedRoles.map(s => {
            return s.value;
        })

        let user = {
            client_id: clientId,
            name: fullName,
            email: email,
            password: password,
            roles: rolesIds
        }

        try {
            let response = await addUser(user);
            history.push('/users');
            toast.success(`User '${user.name}' created.`);
        } catch (error) {
            if (ErrorHelper(error).code == 422) {
                setErrors(ErrorHelper(error));
            }
            else {
                toast.error(ErrorHelper(error).errors[0]);
            }
        }
    }

    const editUserSubmit = async (e: any) => {
        e.stopPropagation();
        e.preventDefault();

        let userId = user?.id;

        let rolesIds = selectedRoles.map(s => {
            return s.value;
        })

        let userUpdate = {
            id: userId,
            name: fullName,
            email: email,
            password: password,
            roles: rolesIds
        }

        try {
            let response = await editUser(userId, userUpdate);
            history.push("/users");
            toast.success(`User '${userUpdate.name}' updated.`);
        } catch (error) {
            if (ErrorHelper(error).code == 422) {
                setErrors(ErrorHelper(error));
            }
            else {
                toast.error(ErrorHelper(error).errors[0]);
            }
        }
    }

    return (
        <div className="page-content container-fluid">
            <UserForm
                selectedClient={client}
                setSelectedClient={setClient}
                clients={allClients}
                roles={roles}
                selectedRoles={selectedRoles}
                setSelectedRoles={setSelectedRoles}
                user={user}
                setFullName={setFullName}
                setEmail={setEmail}
                setPassword={setPassword}
                addUser={addNewUser}
                editUser={editUserSubmit}
                formTitle={formTitle}
                fullName={fullName}
                email={email}
                errors={errors}
            />
        </div>
    );
}
export interface Error {
    code: number;
    errors: any[];
}

export const ErrorHelper = (error: any): Error => {
    let responseError: Error = { code: 0, errors: [] };

    if (error.response != null) {
        if (error.response.data.error.code == 422) {
            responseError.code = error.response.data.error.code;
            responseError.errors = error.response.data.error.validationErrors;
        }
        else {
            responseError.code = error.response.data.error.code
            responseError.errors.push(error.response.data.error.message);
        }
    }

    return responseError;
};
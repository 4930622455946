import { ExecuteGetQueryString } from "../infrastructure/serviceRequest";
import { IClientMeasurements } from "../models/IClientMeasurements";
import { IDeviceMeasurements } from "../models/IDeviceMeasurements";
import IMeasurementsLatest from "../models/IMeasurementsLatest";

export async function getMeasurements(
    startDate: string,
    endDate: string,
    clientId?: number
): Promise<IClientMeasurements> {
    if (clientId != null) {
        const responseAxios = await ExecuteGetQueryString("/measurements", {
            start_date: startDate,
            end_date: endDate,
            client_id: clientId
        });

        return responseAxios.data;
    }
    else {
        const responseAxios = await ExecuteGetQueryString("/measurements", {
            start_date: startDate,
            end_date: endDate
        });

        return responseAxios.data;
    }
}

export async function getMeasurementsLatest(): Promise<IMeasurementsLatest[]> {

    let latestMeasurements: IDeviceMeasurements[];

    const responseAxios = await ExecuteGetQueryString("/measurements/latest", null);

    return latestMeasurements = await responseAxios.data;
}


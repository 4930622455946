/* eslint-disable */
import React, { useState } from "react";
import {
    Nav,
    NavItem,
    NavLink,
    Navbar,
    NavbarBrand,
    Collapse,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import * as data from "./data";
import useICartridge from "../context/hooks/useICartridge";
import useToken from "../context/hooks/useToken";

const Header = () => {
    const {
        user
    } = useICartridge();

    const { removeToken } = useToken();

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const showMobilemenu = () => {
        document.getElementById("main-wrapper").classList.toggle("show-sidebar");
    };

    const sidebarHandler = () => {
        let element = document.getElementById("main-wrapper");
        let navbar = document.getElementById("logobg");

        element.classList.toggle("mini-sidebar");
        if (element.classList.contains("mini-sidebar")) {
            element.setAttribute("data-sidebartype", "mini-sidebar");
            navbar.classList.remove("expand-logo");
        } else {
            element.setAttribute("data-sidebartype", "full");
        }
    };

    return (
        <header className="topbar navbarbg"
            data-navbarbg={"skin6"}
        >
            <Navbar
                className={"top-navbar " + "navbar-dark"}
                expand="md"
            >
                <div
                    className="navbar-header"
                    id="logobg"
                    data-logobg={"skin6"}
                >
                    {/*--------------------------------------------------------------------------------*/}
                    {/* Mobile View Toggler  [visible only after 768px screen]                         */}
                    {/*--------------------------------------------------------------------------------*/}
                    <span
                        className="nav-toggler d-block d-md-none"
                        onClick={showMobilemenu.bind(null)}
                    >
                        <i className="ti-menu ti-close" />
                    </span>
                    {/*--------------------------------------------------------------------------------*/}
                    {/* Logos Or Icon will be goes here for Light Layout && Dark Layout                */}
                    {/*--------------------------------------------------------------------------------*/}
                    <NavbarBrand href="/">
                        <b className="logo-icon">
                            <img src="/images/logoKroma.png" alt="homepage" className="dark-logo" style={{ width: "100%" }} />
                            <img src="/images/logoKroma.png" alt="homepage" className="light-logo" style={{ width: "100%" }} />
                        </b>
                        {/* <span className="logo-text">
                            <img src={logodarktext} alt="homepage" className="dark-logo" />
                            <img src={logolighttext} className="light-logo" alt="homepage" />
                        </span> */}
                    </NavbarBrand>
                    {/*--------------------------------------------------------------------------------*/}
                    {/* Mobile View Toggler  [visible only after 768px screen]                         */}
                    {/*--------------------------------------------------------------------------------*/}
                    <span
                        className="topbartoggler d-block d-md-none"
                        onClick={toggle.bind(null)}
                    >
                        <i className="ti-more" />
                    </span>
                </div>
                <Collapse
                    className="navbarbg"
                    isOpen={isOpen}
                    navbar
                    data-navbarbg={"skin6"}
                    style={{ background: "#009efb" }}
                >
                    <Nav className="float-left" navbar>
                        <NavItem>
                            <NavLink
                                href="#"
                                className="d-none d-md-block"
                                onClick={sidebarHandler.bind(null)}
                            >
                                <i className="icon-arrow-left-circle"></i>
                            </NavLink>
                        </NavItem>
                        {/*--------------------------------------------------------------------------------*/}
                        {/* Start Notifications Dropdown                                                   */}
                        {/*--------------------------------------------------------------------------------*/}
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret>
                                <i className="mdi mdi-message"></i>
                                <div className="notify">
                                    <span className="heartbit"></span>
                                    <span className="point"></span>
                                </div>
                            </DropdownToggle>
                            <DropdownMenu className="mailbox animated bounceInDown">
                                <ul className="list-style-none">
                                    <li>
                                        <div className="font-weight-medium border-bottom rounded-top py-3 px-4">
                                            Notifications
                                        </div>
                                    </li>
                                    <li>
                                        <div className="message-center notifications">
                                            {/*<!-- Message -->*/}
                                            {data.notifications.map((notification, index) => {
                                                return (
                                                    <a
                                                        href="#"
                                                        className="message-item d-flex align-items-center border-bottom px-3 py-2"
                                                        key={index}
                                                    >
                                                        <span
                                                            className={
                                                                "btn rounded-circle btn-" + notification.iconbg
                                                            }
                                                        >
                                                            <i className={notification.iconclass} />
                                                        </span>
                                                        <span className="w-75 d-inline-block v-middle pl-2">
                                                            <h5 className="message-title mb-0 mt-1">
                                                                {notification.title}
                                                            </h5>
                                                            <span className="font-12 text-nowrap d-block text-muted text-truncate">
                                                                {notification.desc}
                                                            </span>
                                                            <span className="font-12 text-nowrap d-block text-muted">
                                                                {notification.time}
                                                            </span>
                                                        </span>
                                                    </a>
                                                );
                                            })}
                                        </div>
                                    </li>
                                    <li>
                                        <a
                                            className="nav-link border-top text-center text-dark pt-3"
                                            href="#"
                                        >
                                            {" "}
                                            <strong>Check all notifications</strong>{" "}
                                            <i className="fa fa-angle-right"></i>{" "}
                                        </a>
                                    </li>
                                </ul>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        {/*--------------------------------------------------------------------------------*/}
                        {/* End Notifications Dropdown                                                     */}
                        {/*--------------------------------------------------------------------------------*/}
                    </Nav>
                    <Nav className="ml-auto float-right" navbar>
                        {/*--------------------------------------------------------------------------------*/}
                        {/* Start Profile Dropdown                                                         */}
                        {/*--------------------------------------------------------------------------------*/}
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret className="pro-pic">
                                <img
                                    src="/images/5.jpg"
                                    alt="user"
                                    className="rounded-circle"
                                    width="31"
                                />
                            </DropdownToggle>
                            <DropdownMenu right className="user-dd animated flipInY">
                                <div className="d-flex no-block align-items-center p-3 mb-2 border-bottom">
                                    <div className="">
                                        <img
                                            src="/images/5.jpg"
                                            alt="user"
                                            className="rounded"
                                            width="80"
                                        />
                                    </div>
                                    <div className="ml-2">
                                        <h4 className="mb-0">{user?.name}</h4>
                                        <p className=" mb-0">{user?.email}</p>
                                        <a
                                            href="/user/profile"
                                            className="btn btn-rounded btn-danger btn-sm"
                                        >
                                            View Profile
                                        </a>
                                    </div>
                                </div>
                                {/* <DropdownItem>
                                    <i className="ti-user mr-1 ml-1" /> My Account
                                </DropdownItem>
                                <DropdownItem>
                                    <i className="ti-wallet mr-1 ml-1" /> My Balance
                                </DropdownItem>
                                <DropdownItem>
                                    <i className="ti-email mr-1 ml-1" /> Inbox
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem>
                                    <i className="ti-settings mr-1 ml-1" /> Account Settings
                                </DropdownItem>
                                <DropdownItem divider /> */}
                                <DropdownItem
                                    onClick={async () => await removeToken()}
                                >
                                    <i className="fa fa-power-off mr-1 ml-1" /> Logout
                                </DropdownItem>
                                <DropdownItem divider />
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        {/*--------------------------------------------------------------------------------*/}
                        {/* End Profile Dropdown                                                           */}
                        {/*--------------------------------------------------------------------------------*/}
                    </Nav>
                </Collapse>
            </Navbar>
        </header>
    );
};

export default Header;

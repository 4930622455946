import { executePost, executePostResetPassword } from "../infrastructure/serviceRequest";

export default async function loginUser(credentials: any) {
    let loginData;

    let responseAxios = await executePost("/login", {
        email: credentials.email,
        password: credentials.password
    });

    loginData = responseAxios.data;

    return loginData;
}

export async function logoutUser() {
    let logoutData;

    let responseAxios = await executePost("/logout");

    logoutData = responseAxios.data;

    return logoutData;
}

export async function refreshToken() {
    let refreshToken;

    let responseAxios = await executePost("/refresh-token");

    refreshToken = responseAxios.data;

    return refreshToken;
}

export async function resetPasswordRequest(email: string) {
    let responseAxios = await executePostResetPassword("/password-reset", { email: email });
    return responseAxios.data;
}

export async function updateNewPasswordRequest(resetToken: string, password: string) {
    let responseAxios = await executePostResetPassword("/password-reset-confirm", {
        reset_token: resetToken,
        password: password
    });
    return responseAxios.data;
}


import { Redirect, Route, Switch, useHistory, useLocation } from "react-router";
import * as routes from "../constants/routes";
import { ALL_ROUTES } from "../constants/routes";
import useToken from "../context/hooks/useToken";
import { Breadcrumb } from "../monsterComponents/components/breadcrumb";
import Sidebar from "../monsterReactTheme/sideBar";
import Header from "../monsterReactTheme/header";
import Footer from "../monsterReactTheme/footer";
import * as containers from "./containers";
import useICartridge from "../context/hooks/useICartridge";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

export default function ContentWrapper() {
    const {
        refreshTokenAction,
        removeToken
    } = useToken();

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const updateDimensions = () => {
            let element = document.getElementById("main-wrapper");
            setWidth(window.innerWidth);
            if (element != null) {
                if (width < 1170) {
                    element.setAttribute("data-sidebartype", "mini-sidebar");
                    element.classList.add("mini-sidebar");
                } else {
                    element.setAttribute(
                        "data-sidebartype",
                        "full"
                    );
                    element.classList.remove("mini-sidebar");
                }
            }
        };

        if (document.readyState === "complete") {
            updateDimensions();
        }
        window.addEventListener("resize", updateDimensions.bind(this));
        window.addEventListener("load", updateDimensions.bind(this));
        return () => {
            window.removeEventListener("load", updateDimensions.bind(this));
            window.removeEventListener("resize", updateDimensions.bind(this));
        };
    }, [width]);

    const handleRefreshToken = async () => {
        const tokenExpirationString = localStorage.getItem('expirationDate');
        const userTokenExpirationDate = JSON.parse(`{ "tokenExpiration": ${tokenExpirationString as string} }`);
        let tokenExpirationDate = userTokenExpirationDate?.tokenExpiration;

        const date = new Date();

        let dateDiff = Math.abs(Math.round((tokenExpirationDate - date.getTime()) / 60000))

        // if diff is 0 then logout the user
        if (dateDiff < 1) {
            await removeToken();
        }
        // here refresh token
        if (dateDiff <= 30) {
            await refreshTokenAction();
        }
    }

    return (
        <Switch>
            <Route key={routes.LOGIN.key} exact path={routes.LOGIN.path} component={containers.Login} />
            <Route key={routes.RESET_PASSWORD.key} exact path={routes.RESET_PASSWORD.path} component={containers.ResetPasswordForm} />
            <Route key={routes.RESET_PASSWORD_UPDATE.key} path={routes.RESET_PASSWORD_UPDATE.path} component={containers.ResetPasswordUpdateForm} />
            <PrivateRoute>
                <Route exact key={routes.ROOT.key} path={routes.ROOT.path} render={props => CreateBredcrumb(props, containers.Dashboard, handleRefreshToken, width)} />
                <Route exact key={routes.DASHBOARD.key} path={routes.DASHBOARD.path} render={props => CreateBredcrumb(props, containers.Dashboard, handleRefreshToken, width)} />
                <Route exact key={routes.ADMIN_DASHBOARD.key} path={routes.ADMIN_DASHBOARD.path} render={props => CreateBredcrumb(props, containers.AdminDashboard, handleRefreshToken, width)} />
                <Route exact key={routes.CLIENTS.key} path={routes.CLIENTS.path} render={props => CreateBredcrumb(props, containers.Clients, handleRefreshToken, width)} />
                <Route exact key={routes.CLIENT_CREATE.key} path={routes.CLIENT_CREATE.path} render={props => CreateBredcrumb(props, containers.CreateClient, handleRefreshToken, width)} />
                <Route exact key={routes.CLIENT_EDIT.key} path={routes.CLIENT_EDIT.path} render={props => CreateBredcrumb(props, containers.CreateClient, handleRefreshToken, width)} />
                <Route exact key={routes.USERS.key} path={routes.USERS.path} render={props => CreateBredcrumb(props, containers.Users, handleRefreshToken, width)} />
                <Route exact key={routes.USER_CREATE.key} path={routes.USER_CREATE.path} render={props => CreateBredcrumb(props, containers.CreateUser, handleRefreshToken, width)} />
                <Route exact key={routes.USER_EDIT.key} path={routes.USER_EDIT.path} render={props => CreateBredcrumb(props, containers.CreateUser, handleRefreshToken, width)} />
                <Route exact key={routes.DEVICES.key} path={routes.DEVICES.path} render={props => CreateBredcrumb(props, containers.Devices, handleRefreshToken, width)} />
                <Route exact key={routes.DEVICE_EDIT.key} path={routes.DEVICE_EDIT.path} render={props => CreateBredcrumb(props, containers.CreateDevice, handleRefreshToken, width)} />
                <Route exact key={routes.USER_PROFILE_EDIT.key} path={routes.USER_PROFILE_EDIT.path} render={props => CreateBredcrumb(props, containers.EditUserProfile, handleRefreshToken, width)} />
            </PrivateRoute>
        </Switch >
    );
};

function CreateBredcrumb(props, Component, handleRefreshToken, width) {

    let crumbs = ALL_ROUTES
        // Get all routes that contain the current one.
        .filter(({ path }) => props.match.path.includes(path))
        // Swap out any dynamic routes with their param values.
        // E.g. "/pizza/:pizzaId" will become "/pizza/1"
        .map(({ path, ...rest }) => ({
            path: Object.keys(props.match.params).length
                ? Object.keys(props.match.params).reduce(
                    (path, param) => path.replace(
                        `:${param}`, props.match.params[param]
                    ), path
                )
                : path,
            ...rest
        }));
    return (
        <div
            id="main-wrapper"
            dir={"ltr"}
            data-theme={"light"}
            data-layout={"vertical"}
            data-sidebartype={width < 800 ? "mini-sidebar" : "full"}
            data-sidebar-position={"fixed"}
            data-header-position={"fixed"}
            data-boxed-layout={"full"}
        >
            <Header />
            <Sidebar />
            <div className="page-wrapper d-block">
                <Breadcrumb crumbs={crumbs} />
                <Component {...props} handleRefreshToken={handleRefreshToken} />
                <Footer />
            </div>
        </div>
    );
}

function PrivateRoute({ children, ...rest }) {
    const {
        token,
        tokenExpiration,
    } = useToken();

    const {
        user
    } = useICartridge();

    let userRoleWithoutRoutesAccess = false

    // if user not admin or clients manager don't give access to next routes
    if (!user?.isAdmin && !user?.isClientManager && (
        window.location.pathname == routes.CLIENTS.path
        || window.location.pathname == routes.CLIENT_CREATE.path
        || window.location.pathname.includes("/clients/edit")
        || window.location.pathname == routes.USER_CREATE.path
        || window.location.pathname.includes("/users/edit")
    )) {
        userRoleWithoutRoutesAccess = true;
    }
    let route: JSX.Element;
    if (!token || tokenExpiration < Date.now()) {
        route = <Route
            {...rest}
            render={() =>
                <Redirect
                    to={{
                        pathname: routes.LOGIN.path,
                    }}
                />
            }
        />
    }
    else {
        // if user is not an admin or client manager has no access to some routes
        if (userRoleWithoutRoutesAccess) {
            toast.error("Access denied.");
            route = <Route
                {...rest}
                render={() =>
                    <Redirect
                        to={{
                            pathname: routes.DASHBOARD.path,
                        }}
                    />}
            />
        }
        else if (window.location.pathname == routes.ROOT.path) {
            route = <Route
                {...rest}
                render={() =>
                    <Redirect
                        to={{
                            pathname: routes.DASHBOARD.path,
                        }}
                    />}
            />
        }
        else {
            route = <Route
                {...rest}
                render={() => children}
            />
        }
    }

    return route;
}
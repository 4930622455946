
export default class FetchParamsBuilder {

    private _config: any;

    constructor() {
        this._config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        };
    }

    public addHeader(key: string, value: string): FetchParamsBuilder {
        if (value == "")
            return;
        this._config.headers[key] = value;
        return this;
    }

    public setParams(params: any): FetchParamsBuilder {
        this._config.params = params;
        return this;
    }

    public setData(data: any): FetchParamsBuilder {
        this._config.data = data;
        return this;
    }

    public setBody(data: any): FetchParamsBuilder {
        this._config.body = data;
        return this;
    }

    public build(): Object {
        return this._config;
    }
}



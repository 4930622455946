import { useContext } from "react";
import IUser from "../../models/IUser";
import { ICartridgeContext } from "../ICartridgeContext";

const useICartridge = () => {
    const [state, setState] = useContext(ICartridgeContext);

    const setICartridgeProperty = (updates: any) => {
        setState((prevState) => ({
            ...prevState,
            ...updates
        }));
    };

    const setUser = (user: IUser) => {
        setICartridgeProperty({ user });
    };

    return {
        user: state.user,
        setUser,

    };
};


export default useICartridge;
import { ExecuteGetQueryString } from "../infrastructure/serviceRequest";
import { ISensorClient } from "../models/ISensorClient";

export async function getSensors(): Promise<ISensorClient[]> {
    let sensors: ISensorClient[];

    let responseAxios = await ExecuteGetQueryString(`/sensors`, null);

    sensors = await responseAxios.data;

    return sensors;
}

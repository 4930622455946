import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useICartridge from '../../context/hooks/useICartridge';
import { IClientMeasurements } from '../../models/IClientMeasurements';
import { IDeviceMeasurements } from '../../models/IDeviceMeasurements';
import { ISensorDevice } from '../../models/ISensorDevice';
import { ChartFiltersBar } from '../../monsterComponents/components/chartFiltersBar';
import { TableReadingsFromSensors } from '../../monsterComponents/components/TableReadingsFromSensors';
import { getMeasurements, getMeasurementsLatest } from '../../services/measurementService';
import { ErrorHelper } from '../../utils/ErrorHelper';
import LineChart from '../chart/ChartJsReact';

export default function Dashboard(props) {
  const { user } = useICartridge();

  let last7Days: string;
  let dateNow: string;
  if (user.isClientManager || user.isAdmin) {
    last7Days = "";
    dateNow = "";
  }
  else {
    last7Days = moment((Date.now() - 7 * 60000 * 60 * 24)).format('YYYY-MM-DD HH:mm') // days/milisecondsInMinute/minutesInHours/hoursInDay
    dateNow = moment(Date.now()).format('YYYY-MM-DD HH:mm');
  }

  const [devices, setDevices] = useState([]);
  const [sensorsTemparaturesPressure, setTemparaturesPressure] = useState([]);
  const [sensorsVibrations, setVibrations] = useState([]);
  const [dateFrom, setDateFrom] = useState(last7Days);
  const [dateTo, setDateTo] = useState(dateNow);
  const [selectedClientDevice, setSelectedClientDevice] = useState(null);
  const [clientDevices, setClientDevices] = useState(null);
  const [latestMeasurements, setLatestMeasurements] = useState([]);
  const [errors, setErrors] = useState({ code: 0, errors: [] });
  const [clientsClientManager, setClientsClientManager] = useState([]);
  const [selectedClientClientManager, setSelectedClientClientManager] = useState(null);
  const [clientRules, setClientRules] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        await props.handleRefreshToken();
        const latestMeasurements = await getMeasurementsLatest();
        setLatestMeasurements(latestMeasurements);

        if (user.isClientManager || user.isAdmin) {
          const usersClients = user.clients.map(c => {
            return ({
              value: c.id,
              label: c.name
            });
          });
          setClientsClientManager(usersClients);
        }
        // call get data on load for other users
        else {
          getChartData();
        }
      } catch (error) {
        toast.error(ErrorHelper(error).errors[0]);
      }
    })();
  }, []);

  let localSelectedClientDevice: { value: number; label: string; };

  const getChartData = async () => {
    if (!isGettingGraphDataFormValid()) {
      return;
    }

    let clientMeasurements: IClientMeasurements;
    setErrors({ code: 0, errors: [] });

    try {
      await props.handleRefreshToken()
      // if user multiclient measurement will be requested with client id
      if (user.isClientManager || user.isAdmin) {
        clientMeasurements = await getMeasurements(
          dateFrom,
          dateTo,
          selectedClientClientManager.value);
      }
      else {
        clientMeasurements = await getMeasurements(
          dateFrom,
          dateTo);
      }

      if (clientMeasurements.devices.length < 1) {
        setClientDevices([]);
        toast.info("There is no data for selected period.");
      }

      clientMeasurements.devices.sort((x, y) => x.id - y.id);
      setDevices(clientMeasurements.devices);

      if (clientMeasurements.rules.length > 0) {
        setClientRules(clientMeasurements.rules);
      }
      else {
        setClientRules([]);
      }

      const clientDevices = clientMeasurements.devices.map(d => {
        return ({
          value: d.id,
          label: d.ident
        });
      });

      if (clientDevices != null && clientDevices.length > 0) {
        // on get data always set first device from response
        localSelectedClientDevice = clientDevices[0];
        setSelectedClientDevice(localSelectedClientDevice);
        setClientDevices(clientDevices);
      }

      setChartData(localSelectedClientDevice, clientMeasurements);

    } catch (error) {
      setErrors(ErrorHelper(error));
      toast.error(ErrorHelper(error).errors[0]);
    }
  };

  const setChartData = (selectedClientDevice: any, clientMeasurements?: IClientMeasurements) => {
    // on set data use the selected device from dropdown
    let selectedDevice;
    if (clientMeasurements != null && clientMeasurements.devices != null) {
      selectedDevice = clientMeasurements.devices.filter(d => d.id == selectedClientDevice?.value)[0];
    }
    else {
      selectedDevice = devices.filter(d => d.id == selectedClientDevice?.value)[0];
    }

    const temperaturesPressure: ISensorDevice[] = selectedDevice?.sensors.filter(s => s.key == "temperature" || s.key == "pressure").sort((x, y) => x.id - y.id);
    const vibrations: ISensorDevice[] = selectedDevice?.sensors.filter(s => s.key == "vibration");

    setTemparaturesPressure(temperaturesPressure);
    setVibrations(vibrations);
  }

  const isGettingGraphDataFormValid = (): boolean => {
    if ((user.isClientManager || user.isAdmin) && (dateFrom == "" || dateTo == "" || selectedClientClientManager == null)) {
      toast.warning("Please select client and set dates.");
      return false;
    }
    else if (dateFrom == "" || dateTo == "") {
      toast.warning("Please set dates.");
      return false;
    }

    return true;
  };

  return (
    <div className="page-content container-fluid">
      <ChartFiltersBar
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        getChartData={getChartData}
        selectedClientDevice={selectedClientDevice}
        setSelectedClientDevice={setSelectedClientDevice}
        clientDevices={clientDevices}
        setChartData={setChartData}
        errors={errors}
        clientsClientManager={clientsClientManager}
        selectedClientClientManager={selectedClientClientManager}
        setSelectedClientMultiUser={setSelectedClientClientManager}
        resetDeviceData={() => setClientDevices([])}
        dateFrom={dateFrom}
        dateTo={dateTo}
        user={user}
      />
      <LineChart
        data={sensorsTemparaturesPressure}
        chartTitle={'Chart - Pressure/Temperature'}
        showY1={true}
        clientRules={clientRules}
      />
      <LineChart
        data={sensorsVibrations}
        chartTitle={'Chart - Vibration'}
        clientRules={clientRules}
      />
      <TableReadingsFromSensors latestMeasurements={latestMeasurements} />
    </div>
  );
}
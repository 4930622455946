
import moment from "moment";
import { SelectDropDown } from "../components/dropDown";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Table } from "reactstrap";

export const DevicesList = (
    {
        devicesList,
        clients,
        selectedClient,
        setSelectedClient,
        isAdminRole,
        isLoading
    }
) => {
    return (
        <div className="card">
            <div className="card-body">
                {isAdminRole &&
                    <div className="row">
                        <div className="col-sm-3 mb-2 ml-0">
                            <SelectDropDown
                                selectedValue={selectedClient}
                                setSelectedValue={setSelectedClient}
                                values={clients.map(c => {
                                    return (
                                        {
                                            value: c.id,
                                            label: c.name
                                        }
                                    );
                                })}
                                placeholder={"Select client"}
                            />
                        </div>
                    </div>
                }
                {
                    (devicesList == null || devicesList.length < 1) && !isLoading ?
                        <div>No results.</div>
                        :
                        <Table responsive size="sm" className="table editable-table customize-table mb-0 v-middle">
                            <thead className="table-light">
                                <tr>
                                    <th className="border-bottom border-top">Indent</th>
                                    <th className="border-bottom border-top">Name</th>
                                    <th className="border-bottom border-top">Created at</th>
                                    <th className="border-bottom border-top"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {devicesList.map((d, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{d.ident}</td>
                                            <td>{d.name}</td>
                                            <td>{moment(d.created_at).format("DD.MM.YYYY. hh:mm:ss")}</td>
                                            <td>
                                                <UncontrolledDropdown setActiveFromChild>
                                                    <DropdownToggle tag="a" className="nav-link">
                                                        <img src={"/images/more-horizontal.svg"} data-feather="more-horizontal" className="feather-sm"></img>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem tag="a" href={`/devices/edit/${d.id}`}>
                                                            Edit
                                                        </DropdownItem>
                                                        <DropdownItem tag="a" href="#">
                                                            Delete
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                }
            </div>
        </div>
    );
}
import { Button } from "reactstrap";

export const EditUserProfileForm = ({
    user,
    state,
    setState,
    editUser
}) => {
    let errorsObjectNames: string[] = [];
    errorsObjectNames = Object.keys(state.errors?.errors);

    let userRoles: string[] = [];
    if (user?.isEditor) {
        userRoles.push("Editor");
    }
    if (user?.isClientManager) {
        userRoles.push("Clients Manager");
    }
    if (user?.isAdmin) {
        userRoles.push("Administrator");
    }

    return (
        <div className="no-block justify-content-center align-items-center">
            <div className="auth-box p-4 bg-white rounded">
                <div>
                    <div className="logo text-center">
                        <span className="db"><img src="/images/5.jpg" alt="user" width="30" className="profile-pic rounded-circle" /></span>
                        <h5 className="font-weight-medium mb-3 mt-1">{state.name}</h5>
                    </div>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-4">
                            <form className="form-horizontal mt-3"
                                onSubmit={async (e) => await editUser(e)}>
                                <div className="mb-3 row text-center">
                                    <div className="col-12">
                                        <label>{userRoles.map(role => {
                                            if (userRoles.length == 1) {
                                                return role;
                                            }
                                            if (role == userRoles[userRoles.length - 1]) {
                                                return role;
                                            }
                                            return `${role}, `;
                                        })}</label>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-12">
                                        <input className="form-control" type="text" required placeholder="Name"
                                            value={state.name ?? ""}
                                            onChange={(e) => setState({ ...state, name: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-12">
                                        <input
                                            className={errorsObjectNames.includes("password") ? "form-control is-invalid" : "form-control"}
                                            type="password"
                                            required
                                            placeholder="Password"
                                            value={state.password ?? ""}
                                            onChange={(e) => setState({ ...state, password: e.target.value })}
                                        />
                                        <div className="invalid-feedback">
                                            {errorsObjectNames.includes("password") &&
                                                state.errors.errors["password"].map(e => {
                                                    return (
                                                        `${e} \n`
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-12">
                                        <input className="form-control" type="email" disabled placeholder="Email"
                                            value={user?.email ?? ""}
                                        />
                                    </div>
                                </div>
                                <div className="text-center">
                                    <div className="col-xs-12">
                                        <Button className="btn d-block w-100 btn-info" type="submit">Save</Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
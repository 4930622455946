import FetchParamsBuilder from "./fetchParamsBuilder";
import axios from "axios";
import useToken from "../context/hooks/useToken";

const ICARTRIDGE_API_URL: string = "https://beta-api.icartridge.app/api";

const GetToken = (): any => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(`{ "token": ${tokenString as string} }`);
    return userToken?.token;
};

export async function executeGet(servicePath: string) {
    // var queryString = Object.keys(queryParams).map(key => `${key}=${queryParams[key].toString().trim().replace(" ", "%20")}`)
    //     .reduce((qString, current) => `${qString}${qString === '?' ? '' : '&'}${current}`, "?");

    var params = new FetchParamsBuilder()
        .build();
    return await axios.get(ICARTRIDGE_API_URL + servicePath);
}

export async function ExecuteGetQueryString(servicePath: string, queryParams: Object, token: string = "") {
    let authToken = token ? token : GetToken();
    var params = new FetchParamsBuilder()
        .addHeader('Authorization', `Bearer ${authToken}`)
        .setParams(queryParams)
        .build();

    let response = await (await axios.get(ICARTRIDGE_API_URL + servicePath, params)).data;
    return response;
}

export async function executePost(servicePath: string, queryParams: Object = null) {
    var params = new FetchParamsBuilder()
        .addHeader('Authorization', `Bearer ${GetToken()}`)
        .setParams(queryParams)
        .build();

    let response = await (await axios.post(ICARTRIDGE_API_URL + servicePath, null, params)).data
    return response;
}

export async function executePostResetPassword(servicePath: string, queryParams: Object = null) {
    var params = new FetchParamsBuilder()
        .setParams(queryParams)
        .build();

    let response = await (await axios.post(ICARTRIDGE_API_URL + servicePath, null, params)).data
    return response;
}

export async function executePut(servicePath: string, queryParams: any) {
    var params = new FetchParamsBuilder()
        .addHeader('Authorization', `Bearer ${GetToken()}`)
        .setParams(queryParams)
        .build();

    let response = await (await axios.put(ICARTRIDGE_API_URL + servicePath, null, params)).data;
    return response;
}
import { useState } from "react";
import { toast } from "react-toastify";
import { Row, Col } from "reactstrap";
import { resetPasswordRequest } from "../../services/loginService";
import { ErrorHelper } from "../../utils/ErrorHelper";

export const ResetPasswordForm = () => {
    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({ code: 0, errors: [] });

    let errorsObjectNames: string[] = [];
    errorsObjectNames = Object.keys(errors?.errors);

    const resetPasswordSubmit = async (e: any) => {
        e.stopPropagation();
        e.preventDefault();

        try {
            let response = await resetPasswordRequest(email);
            setEmail("");
            setErrors({ code: 0, errors: [] });
            toast.success("Email sent. Check your inbox.");
        } catch (error) {
            setErrors(ErrorHelper(error));
            toast.error(ErrorHelper(error).errors[0]);
        }
    }

    return (
        <div className="auth-wrapper d-flex no-block justify-content-center align-items-center">
            <div className="auth-box p-4 bg-white rounded">
                <div>
                    <div className="logo">
                        <h3 className="font-weight-medium mb-3">Recover Password</h3>
                        <span className="text-muted">Enter your Email and instructions will be sent to you!</span>
                    </div>
                    <Row>
                        <Col sm="12">
                            <form
                                className="form-horizontal mt-3 form-material"
                                onSubmit={async (e) => await resetPasswordSubmit(e)}
                            >
                                <div className="form-group mb-4">
                                    <input
                                        className={errorsObjectNames.includes("email") ? "form-control is-invalid" : "form-control"}
                                        type="email"
                                        required={true}
                                        placeholder="Email"
                                        value={email ?? ""}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <div className="invalid-feedback">
                                        {errorsObjectNames.includes("email") && errors?.errors["email"][0]}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="d-flex">
                                        <div className="ms-auto">
                                            <a href="/login"><span>Back to login</span></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group text-center mt-4 mb-3">
                                    <div className="col-xs-12">
                                        <button className="btn btn-info d-block w-100 waves-effect waves-light" type="submit" name="action">Send</button>
                                    </div>
                                </div>
                            </form>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};
import { useState } from "react";
import { Redirect, useHistory, useLocation, useParams } from "react-router";
import { toast, useToast } from "react-toastify";
import { Row } from "reactstrap";
import { updateNewPasswordRequest } from "../../services/loginService";
import { ErrorHelper } from "../../utils/ErrorHelper";

export const ResetPasswordUpdateForm = () => {
    const [state, setState] = useState({
        newPassword: "",
        confirmNewPassword: "",
        errors: { code: 0, errors: [] }
    });

    let errorsObjectNames: string[] = [];
    errorsObjectNames = Object.keys(state.errors?.errors);

    const history = useHistory();

    const { token }: any = useParams();

    // redirect to login if no token 
    if (token == "" || token == undefined) {
        history.push("/");
        return;
    }

    const updateNewPassword = async (e: any) => {
        e.stopPropagation();
        e.preventDefault();

        if (state.newPassword != state.confirmNewPassword) {
            toast.error("Passwords don't match!")
            return;
        }

        try {
            let response = await updateNewPasswordRequest(token, state.newPassword);
            toast.success("Password updated.");
            history.push("/")
        } catch (error) {
            if (ErrorHelper(error).code == 422) {
                setState({ ...state, errors: ErrorHelper(error) })
                errorsObjectNames = Object.keys(ErrorHelper(error)?.errors);
                if (errorsObjectNames.includes("reset_token")) {
                    toast.error(ErrorHelper(error).errors["reset_token"][0]);
                }
            }
            toast.error(ErrorHelper(error).errors[0]);
        }
    }

    return (
        <div className="auth-wrapper d-flex no-block justify-content-center align-items-center">
            <div className="auth-box p-4 bg-white rounded">
                <div>
                    <div className="logo text-center">
                        <h5 className="font-weight-medium mb-3 mt-1">Recover Password</h5>
                    </div>
                    <Row>
                        <div className="col-12">
                            <form className="form-horizontal mt-3"
                                onSubmit={async (e) => await updateNewPassword(e)}
                            >
                                <div className="mb-3 row">
                                    <div className="col-12">
                                        <input
                                            className={errorsObjectNames.includes("password") ? "form-control is-invalid" : "form-control"}
                                            type="password"
                                            required
                                            placeholder="New password"
                                            onChange={(e) => setState({ ...state, newPassword: e.target.value })}
                                        />
                                        <div className="invalid-feedback">
                                            {errorsObjectNames.includes("password") &&
                                                state.errors.errors["password"].map(e => {
                                                    return (
                                                        `${e} \n`
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-12">
                                        <input
                                            className="form-control"
                                            type="password"
                                            required
                                            placeholder="Confirm new password"
                                            onChange={(e) => setState({ ...state, confirmNewPassword: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="text-center">
                                    <div className="col-xs-12">
                                        <button className="btn d-block w-100 btn-info" type="submit">Update password</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Row>
                </div>
            </div>
        </div>
    );
}
import { ExecuteGetQueryString, executePost, executePut } from "../infrastructure/serviceRequest";
import IClient from "../models/IClient";


export async function getClients(page?: number) {
    let params = null;
    if (page != null) {
        params = { page }
    }

    let responseAxios = await ExecuteGetQueryString("/clients", params);

    return responseAxios;
}

export async function getClientsList() {

    let responseAxios = await ExecuteGetQueryString("/clients/list", null);
    return responseAxios.data;
}

export async function getClient(id: number): Promise<IClient> {
    let client: IClient;

    let responseAxios = await ExecuteGetQueryString(`/clients/${id}`, null);

    client = await responseAxios.data;

    return client;
}

export async function addClient(clientName: string, sensors: number[]): Promise<IClient> {
    let clientCreateResponse;

    let responseAxios = await (executePost("/clients", {
        name: clientName,
        sensors
    }));

    clientCreateResponse = responseAxios.data;

    return clientCreateResponse;
}

export async function editClient(id: number, clientName: string, sensors: number[]): Promise<IClient> {
    let clientEditResponse;

    let responseAxios = await (executePut(`/clients/${id}`, {
        name: clientName,
        sensors,
    }));

    clientEditResponse = responseAxios.data;

    return clientEditResponse;
}


import axios from "axios";
import { ExecuteGetQueryString, executePost, executePut } from "../infrastructure/serviceRequest";
import IRoles from "../models/IRoles";
import IUser from "../models/IUser";

export async function getLoggedInUser(token?: string): Promise<IUser> {
    let users: IUser;

    let responseAxios = await ExecuteGetQueryString(`/users/profile`, null, token);

    users = await responseAxios.data;

    return users;
}

export async function getUsers(clientId: number, page: number) {

    let responseAxios = await ExecuteGetQueryString(`/users`, { client_id: clientId, page });

    return responseAxios;
}

export async function getUser(userId: string): Promise<IUser> {
    let users: IUser;

    let responseAxios = await ExecuteGetQueryString(`/users/${userId}`, null);

    users = await responseAxios.data;

    return users;
}

export async function addUser(user: object): Promise<IUser[]> {
    let users: IUser[];

    let responseAxios = await executePost(`/users`, user);

    users = await responseAxios.data;

    return users;
}

export async function editUser(id: number, user: object): Promise<IUser[]> {
    let userEditResponse;

    let responseAxios = await (executePut(`/users/${id}`, user));

    userEditResponse = responseAxios.data;

    return userEditResponse;
}

export async function getRoles(): Promise<IRoles[]> {
    let roles: IRoles[];

    let responseAxios = await ExecuteGetQueryString(`/roles`, null);

    roles = await responseAxios.data;

    return roles;
}
import ContentWrapper from './views/ContentWrapper';
import { ICartridgeContext, ICartridgeProvider } from './context/ICartridgeContext';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Router } from 'react-router-dom';

export default function ICartridge() {
  const [state, setState] = useContext(ICartridgeContext);

  toast.configure({
    hideProgressBar: true
  });

  const user = JSON.parse(localStorage.getItem('loggedInUser'));

  return (
    <ICartridgeProvider
      user={JSON.parse(localStorage.getItem('loggedInUser'))}
    >
      <BrowserRouter>
        <ContentWrapper />
      </BrowserRouter>
    </ICartridgeProvider>
  );
}

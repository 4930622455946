import moment from "moment";
import { Button, Card, CardBody, Col, FormGroup, Row } from "reactstrap";
import { SelectDropDown } from "./dropDown";
import Datetime from "react-datetime";

export const ChartFiltersBar = ({
    dateFrom,
    dateTo,
    setDateFrom,
    setDateTo,
    getChartData,
    selectedClientDevice,
    setSelectedClientDevice,
    clientDevices,
    setChartData,
    errors,
    clientsClientManager,
    selectedClientClientManager,
    setSelectedClientMultiUser,
    resetDeviceData,
    user
}) => {
    let errorsObjectNames: string[] = [];
    errorsObjectNames = Object.keys(errors?.errors);

    return (
        <Row>
            <Col sm="12">
                <Card>
                    <CardBody>
                        <Row>
                            {
                                user.isClientManager &&
                                <Col sm="3">
                                    <SelectDropDown
                                        selectedValue={selectedClientClientManager}
                                        setSelectedValue={setSelectedClientMultiUser}
                                        values={clientsClientManager}
                                        callback={resetDeviceData}
                                        placeholder={"Select client"}
                                    />
                                </Col>
                            }
                            <Col sm="2">
                                <FormGroup>
                                    <Datetime
                                        dateFormat={"DD.MM.YYYY."}
                                        timeFormat={"HH:mm"}
                                        className={errorsObjectNames.includes("start_date") ? "is-invalid" : ""}
                                        onChange={(e) => setDateFrom(moment(e).format('YYYY-MM-DD HH:mm'))}
                                        initialValue={dateFrom != "" ? moment(dateFrom).format("DD.MM.YYYY. HH:mm") : null}
                                        isValidDate={(current) => current.isBefore(Date.now())}
                                        inputProps={{ placeholder: "Date from" }}

                                    />
                                    <div className="invalid-feedback">
                                        {errorsObjectNames.includes("start_date") && errors?.errors["start_date"][0]}
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col sm="2">
                                <FormGroup>
                                    <Datetime
                                        dateFormat={"DD.MM.YYYY."}
                                        timeFormat={"HH:mm"}
                                        className={errorsObjectNames.includes("end_date") ? "is-invalid" : ""}
                                        onChange={(e) => setDateTo(moment(e).format('YYYY-MM-DD HH:mm'))}
                                        initialValue={dateTo != "" ? moment(dateTo).format("DD.MM.YYYY. HH:mm") : null}
                                        isValidDate={(current) => current.isBefore(Date.now())}
                                        inputProps={{ placeholder: "Date to" }}
                                    />
                                    <div className="invalid-feedback">
                                        {errorsObjectNames.includes("end_date") && errors?.errors["end_date"][0]}
                                    </div>
                                </FormGroup>
                            </Col>
                            {
                                clientDevices != null && clientDevices.length > 0 &&
                                <Col sm="2">
                                    <SelectDropDown
                                        selectedValue={selectedClientDevice}
                                        setSelectedValue={setSelectedClientDevice}
                                        values={clientDevices}
                                        callback={setChartData}
                                        placeholder={"Select device"}
                                    />
                                </Col>
                            }
                            <Col sm="2">
                                <Button
                                    className="btn waves-effect waves-light btn-info"
                                    type="button"
                                    //disabled={!dateButtonDisable}
                                    onClick={async () => {
                                        // clear selected device every time before get new set of data
                                        setSelectedClientDevice(null)
                                        await getChartData();
                                    }}
                                >Get data
                                </Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

import { Row } from "reactstrap";

export const Breadcrumb = ({ crumbs }) => {
    // Don't render a single breadcrumb.
    if (crumbs == undefined || crumbs.length <= 1) {
        return null;
    }

    return (
        <div className="page-breadcrumb">
            <Row>
                <div className="col-md-5 align-self-center">
                    <div className="d-flex align-items-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                {crumbs.map(({ title, path }, key) =>
                                    key + 1 === crumbs.length ? (
                                        <li key={key} className="breadcrumb-item active" aria-current="page">{title}</li>
                                    ) : (
                                        <li key={key} className="breadcrumb-item"><a href={path}>{title}</a></li>
                                    )
                                )}
                            </ol>
                        </nav>
                    </div>
                </div>
            </Row>
        </div>
    );
}
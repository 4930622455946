import ReactDOM from 'react-dom';
import ICartridge from './ICartridge';
import reportWebVitals from './reportWebVitals';
import "./assets/scss/style.scss";
import "react-datetime/css/react-datetime.css";

ReactDOM.render(
  <ICartridge />,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

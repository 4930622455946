import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useICartridge from "../../../context/hooks/useICartridge";
import { PaginationComponent } from "../../../monsterComponents/components/paginationComponent";
import { UsersList } from "../../../monsterComponents/lists/usersList";
import { getClientsList } from "../../../services/clientService";
import { getUsers } from "../../../services/userService";
import { ErrorHelper } from "../../../utils/ErrorHelper";

export const Users = (props) => {
    const [users, setUsers] = useState([]);
    const [pagination, setPagination] = useState({ currentPage: 1, count: 0, totalPages: 0 });
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [isAdminRole, setIsAdminRole] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const { user } = useICartridge();

    useEffect(() => {
        (async () => {
            try {
                await props.handleRefreshToken();

                // set users and clients drop down values for admins and clients manager
                if (user != null && (user.isAdmin || user.isClientManager)) {
                    setIsAdminRole(true);
                    let clients = await getClientsList();
                    setClients(clients);
                    let users = await getUsers(selectedClient.value, pagination.currentPage);
                    setUsers(users.data);

                    setPagination({
                        currentPage: users.pagination.currentPage,
                        count: users.pagination.count,
                        totalPages: users.pagination.totalPages,
                    });
                }
                //set users for just editor users
                else if (user != null && !user.isAdmin && !user.isClientManager) {
                    let users = await getUsers(null, pagination.currentPage);
                    setUsers(users.data);
                }

                setIsLoading(false);

            } catch (error) {
                toast.error(ErrorHelper(error).errors[0]);
                setIsLoading(false);
            }
        })();
    }, [selectedClient, pagination.currentPage])


    return (
        <div className="page-content container-fluid">
            <UsersList
                usersList={users}
                clients={clients}
                selectedClient={selectedClient}
                setSelectedClient={setSelectedClient}
                isAdminRole={isAdminRole}
                isLoading={isLoading}
            />
            <PaginationComponent
                pagination={pagination}
                setPagination={setPagination}
            />
        </div>
    );
}
import React from "react";
import { MultiSelectDropDown, SelectDropDown } from "../components/dropDown";

export const UserForm = (
    {
        selectedClient,
        setSelectedClient,
        clients,
        roles,
        selectedRoles,
        setSelectedRoles,
        user,
        setFullName,
        setEmail,
        setPassword,
        addUser,
        editUser,
        formTitle,
        fullName,
        email,
        errors
    }
) => {
    let errorsObjectNames: string[] = [];
    errorsObjectNames = Object.keys(errors?.errors);

    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="card">
                    <div className="border-bottom title-part-padding">
                        <h4 className="card-title mb-0">{formTitle}</h4>
                    </div>
                    <div className="card-body">
                        <form
                            onSubmit={async (e) => {
                                user == null ? await addUser(e) : await editUser(e);
                            }}
                        >
                            <div className="row">
                                <div className="col-md-4 mb-3">
                                    <label>Client<span className="text-danger">*</span></label>
                                    <div className="controls">
                                        <SelectDropDown
                                            selectedValue={selectedClient}
                                            setSelectedValue={setSelectedClient}
                                            values={clients.map(c => {
                                                return ({
                                                    value: c.id,
                                                    label: c.name
                                                });
                                            })}
                                            placeholder={"Select client"}
                                            disabled={user != null}
                                            className={errorsObjectNames.includes("client_id") ? "is-invalid" : ""}
                                        />
                                        <div className="invalid-feedback">
                                            {errorsObjectNames.includes("client_id") && errors?.errors["client_id"][0]}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="name">Full Name<span className="text-danger">*</span></label>
                                    <input
                                        type="text"
                                        className={errorsObjectNames.includes("name") ? "form-control is-invalid" : "form-control"}
                                        id="name"
                                        required
                                        value={fullName ?? ""}
                                        onChange={(e) => setFullName(e.target.value)}
                                    />
                                    <div className="invalid-feedback">
                                        {errorsObjectNames.includes("name") && errors?.errors["name"][0]}
                                    </div>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="email">Email<span className="text-danger">*</span></label>
                                    <div className="input-group">
                                        <span className="input-group-text" id="inputGroupPrepend">@</span>
                                        <input
                                            type="email"
                                            className={errorsObjectNames.includes("email") ? "form-control is-invalid" : "form-control"}
                                            id="email"
                                            required
                                            value={email ?? ""}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <div className="invalid-feedback">
                                            {errorsObjectNames.includes("email") && errors?.errors["email"][0]}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="password">Password<span className="text-danger">*</span></label>
                                    <input
                                        type="password"
                                        className={errorsObjectNames.includes("password") ? "form-control is-invalid" : "form-control"}
                                        id="password"
                                        required
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <div className="invalid-feedback">
                                        {errorsObjectNames.includes("password") && errors?.errors["password"][0]}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="roles">Roles <span className="text-danger">*</span></label>
                                    <MultiSelectDropDown
                                        id="roles"
                                        values={roles.map((r) => {
                                            return (
                                                {
                                                    value: r.id,
                                                    label: r.name
                                                }
                                            );
                                        })}
                                        selectedValues={selectedRoles}
                                        setValues={setSelectedRoles}
                                        entity={user}
                                        placeholder={"Select roles"}
                                        className={errorsObjectNames.includes("roles") ? "is-invalid" : ""}
                                    />
                                    <div className="invalid-feedback">
                                        {errorsObjectNames.includes("roles") && errors?.errors["roles"][0]}
                                    </div>
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="btn btn-success rounded-pill px-4"
                            >
                                <div className="d-flex align-items-center">
                                    <i data-feather="save" className="feather-sm me-1 fill-icon"></i> Save
                                </div>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
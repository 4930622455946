import { Col, Row } from "reactstrap";
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

export const PaginationComponent = ({
    pagination,
    setPagination
}) => {
    const getPaginationPage = () => {
        let paginationPages: any[] = [];

        let indexPaginationStart = 0;
        let paginationLength = 0;

        // set to show always only 5 items in pagination
        if (pagination.currentPage <= 3) {
            indexPaginationStart = 1;

            // set paginationLength for total pages <= 5
            if (pagination.totalPages <= 5) {
                paginationLength = pagination.totalPages;
            }
            else {
                paginationLength = 5;
            }
        }
        else if (pagination.currentPage > 3 && (pagination.totalPages - pagination.currentPage) > 2) {
            indexPaginationStart = pagination.currentPage - 2;
            paginationLength = pagination.currentPage + 2;
        }
        else if ((pagination.totalPages - pagination.currentPage) <= 2) {
            indexPaginationStart = pagination.totalPages - 4;
            paginationLength = pagination.totalPages;
        }

        for (let index = indexPaginationStart; index <= paginationLength; index++) {
            paginationPages.push(
                <PaginationItem
                    active={pagination.currentPage == index}
                    key={index}
                >
                    <PaginationLink
                        last
                        href="#"
                        onClick={() => setPagination({ ...pagination, currentPage: index })}
                    >
                        {index}
                    </PaginationLink>
                </PaginationItem>
            );
        }

        return paginationPages;
    }

    if (pagination.totalPages > 1)
        return (
            <Row>
                <Col lg="12">
                    <Pagination aria-label="Page navigation example"
                        className="pagination justify-content-center">
                        <PaginationItem disabled={pagination.currentPage == 1}>
                            <PaginationLink
                                first
                                href="#"
                                onClick={() => setPagination({ ...pagination, currentPage: 1 })}
                            />
                        </PaginationItem>
                        <PaginationItem disabled={pagination.currentPage == 1}>
                            <PaginationLink
                                previous
                                href="#"
                                onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage - 1 })}
                            />
                        </PaginationItem>
                        {getPaginationPage()}
                        <PaginationItem
                            disabled={pagination.currentPage == pagination.totalPages}>
                            <PaginationLink
                                next
                                href="#"
                                onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage + 1 })}
                            />
                        </PaginationItem>
                        <PaginationItem
                            disabled={pagination.currentPage == pagination.totalPages}>
                            <PaginationLink
                                last
                                href="#"
                                onClick={() => setPagination({ ...pagination, currentPage: pagination.totalPages })}
                            />
                        </PaginationItem>
                    </Pagination>
                </Col>
            </Row>
        );
    else {
        return null;
    }
}
import React, { useState } from "react";
import IUser from "../models/IUser";


export interface IICartridgeContext {
    user: IUser;
    showToastr: boolean;
};

export const defaultState: IICartridgeContext = {
    user: null,
    showToastr: false
};

export const ICartridgeContext: React.Context<[
    IICartridgeContext,
    React.Dispatch<React.SetStateAction<IICartridgeContext>>
]> = React.createContext([
    defaultState,
    (() => { }) as React.Dispatch<React.SetStateAction<IICartridgeContext>>
]);

interface IICartridgeProviderProps {
    children: any | any[];
    user: IUser;
};

export const ICartridgeProvider = ({
    children,
    user,
}: IICartridgeProviderProps) => {

    const updatedState: IICartridgeContext = {
        ...defaultState,
        user,
    };

    const [state, setState] = useState(updatedState);

    return (
        <ICartridgeContext.Provider value={[state, setState]}>
            {children}
        </ICartridgeContext.Provider>
    );
}
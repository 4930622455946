import { Link } from "react-router-dom";
import { Row } from "reactstrap";

export const Login = (props) => {
    return (
        <div id="loginform">
            <div className="logo">
                <h3 className="box-title mb-3">Log In</h3>
            </div>
            <Row>
                <div className="col-12">
                    <form
                        className="form-horizontal mt-3 form-material"
                        id="loginform"
                        onSubmit={(e) => props.handleSubmit(e)}>
                        <div className="form-group mb-3">
                            <div className="">
                                <input
                                    className={"form-control"}
                                    type="email"
                                    required
                                    placeholder="Email"
                                    onChange={(e: any) => props.setEmail(e.target.value)} />
                            </div>
                        </div>
                        <div className="form-group mb-4">
                            <div className="">
                                <input
                                    className="form-control"
                                    type="password"
                                    required
                                    placeholder="Password"
                                    onChange={(e: any) => props.setPassword(e.target.value)} />
                            </div>
                        </div>
                        <Link to={"/reset-password"}>
                            <div className="form-group">
                                <div className="d-flex">
                                    <div className="ms-auto">
                                        <i className="fa fa-lock me-1">
                                        </i> Reset password?
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div className="form-group text-center mt-4 mb-3">
                            <div className="col-xs-12">
                                <button className="btn btn-info d-block w-100 waves-effect waves-light" type="submit">Log In</button>
                            </div>
                        </div>
                    </form>
                </div>
            </Row>
        </div>
    );
}
import 'chartjs-adapter-moment';
import { Line } from 'react-chartjs-2'
import { IRules } from '../../models/IRules';
import { ISensorDevice } from '../../models/ISensorDevice';

interface LineChartProps {
    data: ISensorDevice[];
    chartTitle: string;
    showY1?: boolean;
    clientRules: IRules[];
}

interface ChartDataSet {
    label: string;
    data: chartAxesMeasurements[];
    borderColor: any;
    pointRadius: number;
    borderWidth: number;
    yAxisID: string
}

interface chartAxesMeasurements {
    x: string;
    y: string;
}

const setChartData = (deviceSensors: ISensorDevice[], clientRules: IRules[]) => {

    let data: ChartDataSet[] = deviceSensors?.map(sensor => {
        switch (sensor.key) {
            case "temperature":
                sensor.color = "181, 27, 27";
                sensor.yAxis = "y1";
                break;
            case "pressure":
                sensor.color = "63, 0, 255";
                sensor.yAxis = "y";
                break;
            case "vibration":
                sensor.color = "92, 91, 87";
                sensor.yAxis = "y";
                break;
            default:
                break;
        }

        return ({
            label: sensor.name,
            data: sensor.measurements.map(m => {
                return ({
                    x: m.measurement_date,
                    y: m.value
                });
            }),
            borderColor: `rgb(${sensor.color})`,
            pointRadius: 3,
            borderWidth: 3,
            yAxisID: sensor.yAxis,
            tension: 0.25
        });
    });

    return data;
};

const renderChart = (data, showY1, clientRules: IRules[]) => {
    let dataResponse = setChartData(data, clientRules);    

    return (
        <div style={{ height: "400px" }}>
            <Line
                type={'line'}
                data={{
                    datasets: dataResponse
                }}
                options={{
                    animation: {
                        duration: 0
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: getScales(showY1),
                }}
            />
        </div>
    );
};

const getScales = (showY1: boolean) => {
    let scales;

    scales = {
        x: {
            type: 'time',
            grid: {
                display: false,
            },
            time: {
                displayFormats: {
                    millisecond: 'HH:mm:ss.SSS',
                    second: 'HH:mm:ss',
                    minute: 'HH:mm:ss',
                    hour: 'HH:mm:ss',
                    day: 'DD.MM.YYYY.',
                },
                tooltipFormat: 'DD.MM.YYYY. HH:mm:ss',
            },
        },
        y: {
            type: 'linear',
            display: true,
            position: 'left',
            id: 'y',
            stacked: true,
            beginAtZero: true,
        }
    }
    if (showY1) {
        scales["y1"] = {
            type: 'linear',
            display: true,
            position: 'right',
            id: 'y1',
            stacked: true,
            beginAtZero: true,
            grid: {
                drawOnChartArea: false,
            },
        };
        scales["y"] = {
            type: 'linear',
            display: true,
            position: 'left',
            id: 'y',
            stacked: true,
            beginAtZero: true,
            min: 0,
            max: 5000,
            ticks: {
                stepSize: 1000
            }
        }
    }

    return scales;
}

export default function LineChart(
    {
        data,
        chartTitle,
        showY1 = false,
        clientRules
    }: LineChartProps
) {
    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-body">
                        <div className="d-md-flex align-items-center">
                            <h4 className="card-title">{chartTitle}</h4>
                        </div>
                        <div>{renderChart(data, showY1, clientRules)}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

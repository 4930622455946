import moment from "moment";
import { Table } from "reactstrap";

export const TableReadingsFromSensors = ({ latestMeasurements }) => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="card">
                    <div className="border-bottom title-part-padding">
                        <h4 className="card-title mb-0">Readings from sensors</h4>
                    </div>
                    <Table responsive id="smptable" className="table">
                        <thead>
                            <tr>
                                <th data-field="stargazers_count">User</th>
                                <th data-field="forks_count">Device</th>
                                <th data-field="forks_count">Sensor</th>
                                <th data-field="forks_count">Value</th>
                                <th data-field="description">Date</th>
                            </tr>
                            {latestMeasurements.map((m, key) => {
                                return (
                                    <tr key={key}>
                                        <td data-field="stargazers_count">{m.user_name}</td>
                                        <td data-field="forks_count">{m.device_ident}</td>
                                        <td data-field="description">{m.sensor}</td>
                                        <td data-field="description">{m.value}</td>
                                        <td data-field="description">{moment(m.measurement_date).format("DD.MM.YYYY. hh:mm:ss")}</td>
                                    </tr>
                                );
                            })}
                        </thead>
                    </Table>
                </div>
            </div>
        </div >
    );
}
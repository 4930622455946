import { ExecuteGetQueryString, executePut } from "../infrastructure/serviceRequest";
import IDevice from "../models/IDevice";

export async function getDevices(clientId: number, page: number) {
    let responseAxios = await ExecuteGetQueryString("/devices", { client_id: clientId, page });

    return responseAxios;
}

export async function getDevice(id: number): Promise<IDevice> {
    let device: IDevice;

    let responseAxios = await ExecuteGetQueryString(`/devices/${id}`, null);

    device = await responseAxios.data;

    return device;
}

export async function editDevice(id: number, name: string): Promise<IDevice[]> {
    let deviceEditResponse;

    let responseAxios = await (executePut(`/devices/${id}`, { name }));

    deviceEditResponse = responseAxios.data;

    return deviceEditResponse;
}
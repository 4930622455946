export const LOGIN: IRoute = { key: "login", path: "/login", title: "Login" };
export const ROOT: IRoute = { key: "root", path: "/", title: "Dashboard" };
export const DASHBOARD: IRoute = { key: "dashoard", path: "/dashboard", title: "Dashboard" };
export const ADMIN_DASHBOARD: IRoute = { key: "admin", path: "/admin", title: "Admin" };
export const USERS: IRoute = { key: "users", path: "/users", title: "Users" };
export const USER_CREATE: IRoute = { key: "users_create", path: "/users/create", title: "Create New User" };
export const USER_EDIT: IRoute = { key: "users_edit", path: "/users/edit/:id", title: "Edit User" };
export const CLIENTS: IRoute = { key: "clients", path: "/clients", title: "Clients" };
export const CLIENT_CREATE: IRoute = { key: "clients_create", path: "/clients/create", title: "Create New Client" };
export const CLIENT_EDIT: IRoute = { key: "clients_edit", path: "/clients/edit/:id", title: "Edit Client" };
export const DEVICES: IRoute = { key: "devices", path: "/devices", title: "Devices" };
export const DEVICE_CREATE: IRoute = { key: "devices_create", path: "/devices/create", title: "Create New Device" };
export const DEVICE_EDIT: IRoute = { key: "device_edit", path: "/devices/edit/:id", title: "Edit Device" };
export const RESET_PASSWORD: IRoute = { key: "reset_password", path: "/reset-password", title: "Reset password" };
export const RESET_PASSWORD_UPDATE: IRoute = { key: "reset_password_update", path: "/password-reset/:token", title: "Reset password update" };
export const USER_PROFILE_EDIT: IRoute = { key: "user_profile_edit", path: "/user/profile", title: "Edit user profile" };

export const ALL_ROUTES: IRoute[] = [
    LOGIN,
    ROOT,
    ADMIN_DASHBOARD,
    USERS,
    USER_CREATE,
    USER_EDIT,
    CLIENTS,
    CLIENT_CREATE,
    CLIENT_EDIT,
    DEVICES,
    DEVICE_CREATE,
    DEVICE_EDIT,
    RESET_PASSWORD,
    USER_PROFILE_EDIT,
    RESET_PASSWORD_UPDATE
];

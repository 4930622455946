import React, { useEffect, useState } from "react";
import { Router, useHistory, useParams } from "react-router-dom";
import { ClientForm } from "../../../monsterComponents/forms/clientForm";
import { addClient, editClient, getClient } from "../../../services/clientService";
import { getSensors } from "../../../services/sensorService";
import { ErrorHelper } from "../../../utils/ErrorHelper";
import { toast } from 'react-toastify';
import useICartridge from "../../../context/hooks/useICartridge";

export const CreateClient = (props) => {
    const { id }: any = useParams();
    const [client, setClient] = useState(null);
    const [name, setName] = useState("");
    const [clientsSensors, setClientSensors] = useState([]);
    const [sensors, setSensors] = useState([]);
    const [errors, setErrors] = useState({ code: 0, errors: [] });

    const history = useHistory();

    useEffect(() => {
        (async () => {
            try {
                await props.handleRefreshToken()
                if (id != null && id > 0) {
                    let client = await getClient(id);
                    setClient(client);
                    setName(client.name);
                    // map object sensors for multi select field 
                    let clientSensors = client.sensors.map(cs => {
                        return (
                            {
                                value: cs.id,
                                label: cs.name
                            }
                        );
                    });
                    setClientSensors(clientSensors);
                }

                let sensors = await getSensors();
                setSensors(sensors);
            } catch (error) {
                toast.error(ErrorHelper(error).errors[0]);

                if (ErrorHelper(error).code == 403) {
                    history.push("/");
                    return;
                }
            }
        })();
    }, [])

    let formTitle = id != null ? "Edit Client" : "Add Client"

    const addNewClient = async (e: any) => {
        e.stopPropagation();
        e.preventDefault();

        let sensorIds = clientsSensors.map(s => {
            return s.value;
        })

        try {
            await props.handleRefreshToken()
            let response = await addClient(name, sensorIds);
            history.push('/clients');
            toast.success(`Client '${response.name}' created.`);
        } catch (error) {
            if (ErrorHelper(error).code == 422) {
                setErrors(ErrorHelper(error));
            }
            else {
                toast.error(ErrorHelper(error).errors[0]);
            }
        }
    }

    const editClientSubmit = async (e: any) => {
        e.stopPropagation();
        e.preventDefault();

        let sensorIds = clientsSensors.map(s => {
            return s.value;
        })

        try {
            await props.handleRefreshToken()
            let response = await editClient(id, name, sensorIds);            
            history.push('/clients');
            toast.success(`Client '${name}' updated.`);
        } catch (error) {
            if (ErrorHelper(error).code == 422) {
                setErrors(ErrorHelper(error));
            }
            else {
                toast.error(ErrorHelper(error).errors[0]);
            }
        }
    }

    return (
        <div className="page-content container-fluid">
            <ClientForm
                name={name}
                setName={setName}
                sensors={sensors}
                clientSensors={clientsSensors}
                setClientSensors={setClientSensors}
                formTitle={formTitle}
                client={client}
                addClient={addNewClient}
                editClient={editClientSubmit}
                errors={errors}
            />
        </div>
    );
}
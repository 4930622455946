import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { DeviceForm } from "../../../monsterComponents/forms/deviceForm";
import { editDevice, getDevice } from "../../../services/deviceService";
import { ErrorHelper } from "../../../utils/ErrorHelper";

export const CreateDevice = (props) => {
    const { id }: any = useParams();

    const [state, setState] = useState({
        device: null,
        name: "",
        ident: "",
        createdAt: "",
        errors: { code: 0, errors: [] }
    });

    const history = useHistory();

    useEffect(() => {
        (async () => {
            try {
                await props.handleRefreshToken()

                if (id != null && id > 0) {
                    let device = await getDevice(id);

                    setState({
                        ...state,
                        device: device,
                        name: device.name,
                        ident: device.ident,
                        createdAt: moment(device.created_at).format("DD.MM.YYYY. hh:mm:ss")
                    });
                }
            } catch (error) {
                setState({ ...state, errors: ErrorHelper(error) })
            }
        })();
    }, [])

    const editDeviceSubmit = async (e: any) => {
        e.stopPropagation();
        e.preventDefault();

        let deviceId = state.device?.id;
        let deviceName = state.name;

        try {
            let response = await editDevice(deviceId, deviceName);            
            history.push('/devices');
            toast.success(`Device '${deviceName}' updated.`);
        } catch (error) {
            setState({ ...state, errors: ErrorHelper(error) })
        }
    }

    return (
        <div className="page-content container-fluid">
            <DeviceForm
                state={state}
                setState={setState}
                editDevice={editDeviceSubmit}
            />
        </div>
    );
}